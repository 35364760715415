export default function cart() {
	// Cart & Checkout Page
	const project_type = $( '#emp_project_type' );
	const checkout_button = $( '.wc-proceed-to-checkout .checkout-button' );

	// Page load
	const val = project_type.val();
	if ( val ) {
		$( '#project_descs>li' ).hide();
		$( '#project_descs>li.project-' + val ).show();
	}

	project_type.on( 'change', function ( e ) {
		const val = $( this ).val();
		const href = checkout_button.attr( 'href' );
		checkout_button.attr( 'href', href + '?project_type=' + val );

		$( '#project_descs>li' ).hide();
		$( '#project_descs>li.project-' + val ).show();
	} );

	checkout_button.on( 'click', function ( e ) {
		const val = project_type.val();
		if ( val === '' ) {
			e.preventDefault();
			alert( 'Please select a project type in order to continue!' );
		}
	} );
}
