import cart from './modules/cart';
import checkout from './modules/checkout';
import song_data from './modules/song_data';
import admin from './modules/admin';
import workflow from './modules/workflow';
import misc from './modules/misc';
import translations from './modules/translations';

jQuery( document ).ready( function ( $ ) {
	cart();
	checkout();
	song_data();
	workflow();
	misc();
	translations();

	if ( $( 'body' ).hasClass( 'wp-admin' ) ) {
		admin();
	}
} );
