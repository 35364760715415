import Cleave from 'cleave.js';
import 'magnific-popup';

export default function song_data() {
	const woo_order_items = $( 'body.wp-admin #woocommerce-order-items' );

	if ( woo_order_items.length > 0 ) {
		get_song_table_data( true );
	}

	// Get products for admin view
	function get_admin_products() {
		let woo_order_items_product_ids = [];
		const woo_order_items_links = woo_order_items.find( '#order_line_items>tr>td.name>a' );
		if ( woo_order_items_links.length ) {
			woo_order_items_links.each( function ( index, item ) {
				const href = $( this ).attr( 'href' );
				const url = new URL( href );
				const params = new URLSearchParams( url.search );
				const post_id = params.get( 'post' );
				if ( post_id ) {
					woo_order_items_product_ids.push( post_id );
				}
			} );
		}

		return woo_order_items_product_ids;
	}

	// Build song data table
	$( '.button.song-data-table-build' ).on( 'click', function ( e ) {
		e.preventDefault();
		const configurations = $( '#emp_configurations' ).val();

		if ( configurations.length === 0 ) {
			alert( 'Please select at least one configuration!' );
			return false;
		}

		let has_blanks = false;
		let error_fields = [];

		$( '.woocommerce-emp-fields .form-row.validate-required:visible' ).removeClass( 'woocommerce-invalid' ).removeClass( 'woocommerce-validated' );
		$( '.woocommerce-emp-fields .form-row.validate-required:visible input:not(.select2-search__field)' ).each( function ( index, item ) {
			const val = $( this ).val();
			const wrapper = $( this ).closest( '.form-row' );

			if ( !val ) {
				wrapper.addClass( 'woocommerce-invalid' );
				has_blanks = true;
				const label = wrapper.find( '>label' ).text().replace( '*', '' );
				error_fields.push( label );
			} else {
				wrapper.addClass( 'woocommerce-validated' );
			}
		} );

		if ( has_blanks ) {
			alert( 'Please fill out all required fields!\n\nMissing fields:\n' + error_fields.join( ',' ) );
			return false;
		}

		if ( woo_order_items.length === 0 ) {
			$( this ).hide();
		}

		get_song_table_data();
	} );

	function get_song_table_data( load_data = false ) {
		const song_data_tables = $( '#song_data_tables' );
		const nonce = $( '.button.song-data-table-build' ).attr( 'data-nonce' );
		const configurations = $( '#emp_configurations' ).val();
		const artist_name = $( '#emp_artist_name' ).val();
		const retail_price = $( '#emp_retail_price' ).val();
		const product_number = $( '#emp_product_number' ).val();
		const number_of_songs = $( '#emp_songs' ).val();
		const number_of_copies = $( '#emp_copies' ).val();
		const number_of_downloads = $( '#emp_downloads' ).val();
		let is_admin = false;

		let song_data_obj = {};
		$( 'input[name*=\'emp_song_data\']' ).each( function ( index, element ) {
			let parts = /emp_song_data\[(.*)\]\[(.*)\]\[(.*)\]/gm.exec( element.name );

			if ( !(
				parts[1] in song_data_obj
			) ) {
				song_data_obj[parts[1]] = {};
			}

			if ( !(
				parts[2] in song_data_obj[parts[1]]
			) ) {
				song_data_obj[parts[1]][parts[2]] = {};
			}

			song_data_obj[parts[1]][parts[2]][parts[3]] = $( element ).val();
		} );

		let action = 'get_song_table_data';
		let woo_order_items_product_ids = [];

		if ( woo_order_items.length > 0 ) {
			action = 'admin_get_song_table_data';
			woo_order_items_product_ids = get_admin_products();
			is_admin = true;
		}

		$.ajax( {
			url: emp_plugin.ajax_url,
			method: 'POST',
			xhrFields: {
				withCredentials: false
			},
			data: {
				'action': action,
				'_wpnonce': nonce,
				'configurations': configurations,
				'artist_name': artist_name,
				'retail_price': retail_price,
				'product_number': product_number,
				'number_of_songs': number_of_songs,
				'number_of_copies': number_of_copies,
				'number_of_downloads': number_of_downloads,
				'products': woo_order_items_product_ids,
				'order_id': $( '#_acf_post_id' ).val(),
				'song_data': song_data_obj,
				'is_admin': is_admin
			},
			dataType: 'html',
			beforeSend: function () {
				song_data_tables.html( 'Loading...' );
			},
			success: function ( response, textStatus, jqXHR ) {
				song_data_tables.html( response );

				// Init cleave
				document.querySelectorAll( '.time' ).forEach( function ( el ) {
					new Cleave( el, {
						time: true,
						timePattern: ['m', 's']
					} );
				} );

				document.querySelectorAll( '.currency' ).forEach( function ( el ) {
					new Cleave( el, {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 100
					} );
				} );

				document.querySelectorAll( '.date' ).forEach( function ( el ) {
					new Cleave( el, {
						date: true,
						delimiter: '/',
						datePattern: ['m', 'd', 'Y']
					} );
				} );

				// Tooltips
				$( '#song_data_tables input' ).tooltip();

				$( '.song-data-table-calculate' ).show();
			},
			error: function ( jqXHR, textStatus, errorThrown ) {
				alert( 'An error has occurred!' );
			}
		} );
	}

	// Calculate song data
	$( '.song-data-table-calculate' ).on( 'click', function ( e ) {
		e.preventDefault();
		const nonce = $( this ).attr( 'data-nonce' );
		let action = 'calculate_song_table_data';
		let song_data = $( 'input[name^="emp_song_data"]' ).serialize();
		song_data = song_data.replace( /%5B/g, '[' );
		song_data = song_data.replace( /%5D/g, ']' );

		$( 'input[name^="emp_song_data"]' ).removeClass( 'input-error' );

		let has_blanks = false;
		let error_fields = [];

		$( 'input[name^="emp_song_data"]' ).each( function ( index, item ) {
			const is_required = $( this ).prop( 'required' );
			const val = $( this ).val();

			if ( is_required && !val ) {
				$( this ).addClass( 'input-error' );
				has_blanks = true;
				const label = $( this ).attr( 'aria-label' );

				if ( !error_fields.includes( label ) ) {
					error_fields.push( label );
				}
			}
		} );

		if ( has_blanks ) {
			alert( 'Please fill out all required fields!\n\nMissing fields:\n' + error_fields.join( ',' ) );
			return false;
		}

		if ( woo_order_items.length > 0 ) {
			action = 'admin_calculate_song_table_data';
		}

		$.ajax( {
			url: emp_plugin.ajax_url,
			method: 'POST',
			xhrFields: {
				withCredentials: false
			},
			data: {
				'action': action,
				'_wpnonce': nonce,
				'song_data': song_data,
				'emp_term': $( '#emp_term' ).val(),
				'emp_term_unit': $( '#emp_term_unit' ).val()
			},
			dataType: 'json',
			beforeSend: function () {
				$( 'input[name^="emp_song_data"]' ).removeClass( 'input-error' );
			},
			success: function ( response, textStatus, jqXHR ) {
				const songs = response.data.songs;
				if ( songs ) {
					for ( let song_id in songs ) {
						for ( let config_id in songs[song_id] ) {
							for ( let key in songs[song_id][config_id] ) {
								$( 'input[name="emp_song_data[' + song_id + '][' + config_id + '][' + key + ']"]' ).val( songs[song_id][config_id][key] );
							}
						}
					}
					$( '.song-data-table-header>div:nth-last-child(-n+2)' ).css( 'visibility', 'visible' );
					$( '.song-data-table-row>div>input:not(.emp-song-data-artist-name)' ).show();
					$( '.song-data-table-next' ).show();
					$( 'body' ).trigger( 'update_checkout' );
				} else {
					alert( 'An error has occurred!' );
				}
			},
			error: function ( jqXHR, textStatus, errorThrown ) {
				alert( 'An error has occurred!' );
			}
		} );
	} );

	// Show configuration descriptions & pop-ups
	$( '#emp_configurations' ).on( 'change', function ( e ) {
		$( '#song_data_config_descs>li' ).hide();
		const selected_configs = $( this ).val();
		let selected_recording_configs = [];
		let selected_recording_configs_names = [];
		let selected_non_recording_configs = [];
		let selected_non_recording_configs_names = [];

		for ( let selected_config of selected_configs ) {
			selected_config = parseInt( selected_config );
			$( '#song_data_config_descs>li.config-' + selected_config ).show();

			for ( const [key, value] of Object.entries( emp_plugin.artist_recording_configs ) ) {
				if ( selected_config == key ) {
					selected_recording_configs.push( selected_config );
					selected_recording_configs_names.push( value.title );
					break;
				}
			}

			for ( const [key, value] of Object.entries( emp_plugin.artist_recording_config_links ) ) {
				if ( selected_config == key ) {
					selected_non_recording_configs.push( selected_config );
					selected_non_recording_configs_names.push( value.title );
					break;
				}
			}
		}

		let woo_order_items_product_ids = [];

		if ( woo_order_items.length > 0 ) {
			//action = 'admin_get_song_table_data';
			woo_order_items_product_ids = get_admin_products();
		}

		$.ajax( {
			url: emp_plugin.ajax_url,
			method: 'POST',
			xhrFields: {
				withCredentials: false
			},
			data: {
				'action': 'check_songs_recordings_control',
				'_wpnonce': emp_plugin.check_songs_recordings_control_nonce,
				'products': woo_order_items_product_ids
			},
			dataType: 'json',
			success: function ( response, textStatus, jqXHR ) {
				let has_pair = false;

				let recording_config_text = emp_plugin.recording_configuration_text;
				recording_config_text = recording_config_text.replace( '%configs%', selected_recording_configs_names.join( ', ' ) );

				let non_recording_config_text = emp_plugin.non_recording_configuration_text;
				non_recording_config_text = non_recording_config_text.replace( '%configs%', selected_non_recording_configs_names.join( ', ' ) );
				let message = '';

				if ( selected_recording_configs.length > 0 && response.data.songs ) {
					for ( let [key, value] of Object.entries( response.data.songs ) ) {
						if ( value ) {
							message += recording_config_text;
							break;
						}
					}
				}

				console.log( selected_non_recording_configs );

				if ( selected_non_recording_configs.length > 0 && response.data.recordings ) {
					for ( let [key, value] of Object.entries( response.data.recordings ) ) {
						if ( value ) {
							message += non_recording_config_text;
							break;
						}
					}
				}

				// Check for pairs
				for ( const [key, value] of Object.entries( emp_plugin.artist_recording_config_links ) ) {
					for ( const nrc of selected_non_recording_configs ) {
						// Has non-recording
						if ( nrc == key ) {
							for ( const src of selected_recording_configs ) {
								// Has recording
								if ( src == value.id ) {
									has_pair = true;
									break;
								}
							}
						}
					}
				}

				if ( message && !has_pair ) {
					$( '<div />' ).html( message ).dialog( {
						modal: true,
						buttons: {
							Ok: function () {
								$( this ).dialog( 'close' );
							}
						}
					} );
				}
			},
			error: function ( jqXHR, textStatus, errorThrown ) {
				alert( 'An error has occurred!' );
			}
		} );

		if ( !$( '.button.song-data-table-build' ).is( ':visible' ) ) {
			$( '.button.song-data-table-build' ).trigger( 'click' );
		}
	} );

	// Add artist recording config
	$( '#song_data_tables' ).on( 'click', '.song-data-table-row-add-artist-recording-config', function ( e ) {
		e.preventDefault();
		const linked_config = $( this ).attr( 'data-linked-config' );
		$( '#emp_configurations option[value="' + linked_config + '"]' ).prop( 'selected', true );
		$( '#emp_configurations' ).trigger( 'change' );
		$( '.button.song-data-table-build' ).trigger( 'click' );
	} );

	// Add recording modal
	$( '#song_data_tables' ).magnificPopup( {
		delegate: '.song-table-data-row-select-recording',
		type: 'inline'
	} );

	$( document ).on( 'click', '.song-data-table-row-modal-form button', function ( e ) {
		e.preventDefault();
		$.magnificPopup.close();
		const recording_id = $( this ).parents( '.song-data-table-row-modal-form' ).find( '.song-data-table-row-modal-radio:checked' ).val();
		const artist_name = $( this ).parents( '.song-data-table-row-modal-form' ).find( '.song-data-table-row-modal-input' ).val();
		const isrc = $( this ).parents( '.song-data-table-row-modal-form' ).find( '.song-data-table-row-modal-radio:checked' ).attr( 'data-isrc' );
		const parent = $( this ).parents( '.song-data-table-row' );
		const button = parent.find( '.song-table-data-row-select-recording' );

		if ( recording_id === 'custom' ) {
			parent.find( '.emp-song-data-artist-name' ).val( artist_name );
			button.text( artist_name );
		} else {
			parent.find( '.emp-song-data-recording-id' ).val( recording_id );
			const recording_artists = $( this ).parents( '.song-data-table-row-modal-form' ).find( '.song-data-table-row-modal-radio:checked' ).attr( 'data-artists' );
			parent.find( '.emp-song-data-artist-name' ).val( recording_artists );
			button.text( recording_artists );
		}

		parent.find( '.emp-song-data-isrc' ).val( isrc );
	} );

	$( document ).on( 'click', '.embed-toggle>a', function ( e ) {
		e.preventDefault();
		$( this ).next( 'div' ).slideToggle();
	} );

	// Remove config
	$( '#song_data_tables' ).on( 'click', '.song-data-table-row-remove', function ( e ) {
		e.preventDefault();
		const confirm = window.confirm( 'Really remove this configuration?' );
		if ( confirm ) {
			$( this ).parent( '.song-data-table-row' ).remove();
		}
	} );
}
