export default function translations() {
	$( '.emp-form-song select' ).on( 'change', function ( e ) {
		const song_id = $( this ).val();
		$.ajax( {
			url: emp_plugin.ajax_url,
			method: 'POST',
			xhrFields: {
				withCredentials: false
			},
			data: {
				'action': 'get_songwriters',
				'_wpnonce': emp_plugin.songwriters_nonce,
				'song_id': song_id
			},
			dataType: 'json',
			beforeSend: function () {
				$( '.emp-form-songwriters textarea' ).val( 'Loading...' );
			},
			success: function ( response, textStatus, jqXHR ) {
				if ( response.success === true ) {
					$( '.emp-form-songwriters textarea' ).val( response.data.composers );
				} else {
					alert( response.data.msg );
					$( '.emp-form-songwriters textarea' ).val( '' );
				}
			},
			error: function ( jqXHR, textStatus, errorThrown ) {
				alert( 'An error has occurred!' );
				$( '.emp-form-songwriters textarea' ).val( '' );
			}
		} );
	} );
}
