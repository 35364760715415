import Cleave from 'cleave.js';

export default function misc() {
	document.querySelectorAll( '.date-iso' ).forEach( function ( el ) {
		new Cleave( el, {
			date: true,
			delimiter: '-',
			datePattern: ['Y', 'm', 'd']
		} );
	} );
}
