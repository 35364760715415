import Cleave from 'cleave.js';

export default function checkout() {
	const woo_order_items = $( 'body.wp-admin #woocommerce-order-items' );

	// Input Formatting
	document.querySelectorAll( '.currency' ).forEach( function ( el ) {
		new Cleave( el, {
			numeral: true,
			numeralThousandsGroupStyle: 'thousand',
			numeralDecimalScale: 100
		} );
	} );

	document.querySelectorAll( '.date' ).forEach( function ( el ) {
		new Cleave( el, {
			date: true,
			delimiter: '/',
			datePattern: ['m', 'd', 'Y']
		} );
	} );

	// Custom Validation
	$( '.woocommerce-emp-fields input' ).on( 'blur change', function () {
		const wrapper = $( this ).closest( '.form-row' );
		const val = $( this ).val();
		if ( !val ) {
			wrapper.addClass( 'woocommerce-invalid' );
		} else {
			wrapper.addClass( 'woocommerce-validated' );
		}
	} );

	// Show order complete form
	$( '.song-data-table-next' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '#order_review_heading, #order_review' ).show();
	} );

	// SelectWoo
	const select_woo_items = $( '#emp_project_type, #emp_term_unit, #emp_license, #emp_configurations' );
	if ( select_woo_items.length > 0 && $.isFunction( $.fn.selectWoo ) ) {
		select_woo_items.selectWoo();
	}

	// Checkout Page
	const c_project_type = $( '.woocommerce-emp-fields #emp_project_type' );
	empFields();
	c_project_type.on( 'change', empFields );

	// Conditional logic for fields
	function empFields() {
		// Show hide fields
		const project_type = c_project_type.val();
		const emp_class = '.emp-field.emp-field-' + project_type;
		$( '.emp-field' ).hide();
		$( emp_class ).show();

		// Adjust license drop down
		let license = '';
		if ( typeof emp_plugin.project_licenses[project_type] !== 'undefined' ) {
			$( '#emp_license option' ).prop( 'disabled', true ).prop( 'selected', false );

			for ( let project_license of emp_plugin.project_licenses[project_type] ) {
				$( '#emp_license option[value="' + project_license + '"]' ).prop( 'disabled', false );
			}

			if ( emp_plugin.project_licenses[project_type].length === 1 ) {
				license = emp_plugin.project_licenses[project_type][0];
			}

			$( '#emp_license' ).val( license );
		}

		// Adjust configurations
		$( '#emp_configurations option' ).prop( 'disabled', false );
		if ( typeof emp_plugin.project_configs[project_type] !== 'undefined' ) {
			$( '#emp_configurations option' ).each( function ( index, item ) {
				const config_id = $( this ).val();
				if ( !emp_plugin.project_configs[project_type].includes( parseInt( config_id ) ) ) {
					$( this ).prop( 'disabled', true ).prop( 'selected', false );
				}
			} );
		}
		if ( $.isFunction( $.fn.selectWoo ) ) {
			$( '#emp_license, #emp_configurations' ).selectWoo();
		}
	}

	// Term field
	$( '#emp_term_unit' ).on( 'change', function ( e ) {
		e.preventDefault();
		const val = $( this ).val();

		if ( val === 'perpetuity' ) {
			$( '#emp_term' ).val( '' );
			$( '#emp_term_field' ).hide();
		} else {
			$( '#emp_term_field' ).show();
		}
	} );

	// TinyMCE
	tinyMCE.init( {
		menubar: false,
		plugins: 'paste',
		selector: '#emp_notes, #emp_product_configurations'
	} );
}
