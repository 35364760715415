export default function workflow() {
	$( '#emp-submit-workflow-btn' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '#gravityflow_update_button' ).trigger( 'click' );
	} );

	$( '#emp-print-btn' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '.detail-view-print>a' ).trigger( 'click' );
	} );
}
