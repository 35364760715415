export default function admin() {
	$.expr[':'].textEquals = $.expr.createPseudo( function ( arg ) {
		return function ( elem ) {
			return $( elem ).text().match( '^' + arg + '$' );
		};
	} );

	const license_tr = $( '.entry-view-field-name:textEquals(\'License\')' ).parent( 'tr' );

	license_tr.find( 'td' ).append( ' (+/-)' );
	license_tr.next( 'tr' ).hide();

	license_tr.on( 'click', function ( e ) {
		e.preventDefault();
		$( this ).next( 'tr' ).toggle();
	} );

	// TinyMCE
	tinyMCE.init( {
		menubar: false,
		plugins: 'paste',
		selector: '#field_2_14 textarea, #field_2_26 textarea, #emp_scene_description, #emp_media_rights_requested, #emp_options'
	} );

	// Quick Delete
	$( 'a.pdf-quick-delete' ).on( 'click', function ( e ) {
		e.preventDefault();
		const self = $( this );
		const nonce = $( this ).attr( 'data-nonce' );
		const pdf_id = $( 'input[name="acf[field_6142260bf103c]"]' ).val();
		if ( pdf_id ) {
			$.ajax( {
				url: emp_plugin.ajax_url,
				method: 'POST',
				xhrFields: {
					withCredentials: false
				},
				data: {
					'action': 'quick_delete_pdf',
					'_wpnonce': nonce,
					'pdf_id': pdf_id
				},
				dataType: 'json',
				beforeSend: function () {
					self.text( 'Loading...' );
				},
				success: function ( response, textStatus, jqXHR ) {
					$( 'input[name="acf[field_6142260bf103c]"]' ).next( '.file-wrap' ).find( '.-cancel' ).trigger( 'click' );
					alert( response.data.msg );
				},
				error: function ( jqXHR, textStatus, errorThrown ) {
					alert( 'An error has occurred!' );
				},
				complete: function () {
					self.text( 'Quick Delete' );
				}
			} );
		}
	} );
}
